<template>
  <div class="optionExplanations">
    <router-link
      class="optionExplanation"
      v-for="option in menuOptionExplanaions"
      :key="option.option"
      :to="{ name: option.option }"
      @click.native="
        toggleExpandableElement('expandableMenu');
        toggleClass('menuIconContainer', 'change');
      "
    >
      <div :id="option.option">
        <h3>{{ $t(`headers.${option.option.toLowerCase()}`) }}</h3>
        <p>
          {{ $t(`view.home.${option.option.toLowerCase()}-text`) }}
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MenuOptionExplanations',
  data() {
    return {
      menuOptionExplanaions: [
        {
          option: 'News',
        },
        {
          option: 'About',
        },
        {
          option: 'Music',
        },
        {
          option: 'Store',
        },
        {
          option: 'Contact',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.optionExplanations {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
}

@media (min-width: 750px) {
  .optionExplanations {
    flex-direction: row;
    align-items: unset;
  }
}

.optionExplanation {
  border: 1px solid #646154;
  border-radius: 10px;
  margin: 5px;
  text-align: center;
  width: 300px;
  padding: 5px;
  text-decoration: none;
  color: #e4e1d4;

  &:hover {
    transition: 0.3s ease;
    filter: brightness(150%);
    background-color: #202020;
  }
}
</style>
