<template>
  <div class="slideshow">
    <div class="imageContainer">
      <img
        :src="currentImage"
        :alt="`${this.images[this.currentIndex].alternativeText}`"
      />
    </div>
    <div class="slideshowController">
      <span class="slideshowPrev" v-on:click="previousImage()">↼</span>
      <div
        v-for="index in images.length"
        :key="index"
        class="slideshowButton"
        v-bind:class="{ slideshowActive: currentIndex == index - 1 }"
        v-on:click="setImage(index - 1)"
      ></div>
      <span class="slideshowNext" v-on:click="nextImage()">⇁</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slideshow',
  data() {
    return {
      images: [
        {
          id: 0,
          name: '1_ToDust.jpg',
          alternativeText: 'To Dust live',
        },
        {
          id: 1,
          name: '2_ToDust.jpg',
          alternativeText: 'To Dust live',
        },
        {
          id: 2,
          name: '3_ToDust.jpg',
          alternativeText: 'To Dust live',
        },
      ],
      currentIndex: 0,
      timer: null,
    };
  },
  methods: {
    nextImage() {
      this.setImage(this.currentIndex + 1);
      this.resetSlideshow();
    },
    previousImage() {
      this.setImage(this.currentIndex - 1);
      this.resetSlideshow();
    },
    setImage(index) {
      if (index >= this.images.length) {
        this.currentIndex = 0;
      } else if (index < 0) {
        this.currentIndex = this.images.length - 1;
      } else {
        this.currentIndex = index;
      }
    },
    resetSlideshow() {
      clearTimeout(this.timer);
      this.timer = setInterval(this.nextImage, 5000);
    },
  },
  computed: {
    currentImage() {
      return require(`../../assets/images/carousel/${
        this.images[this.currentIndex].name
      }`);
    },
  },
  mounted() {
    this.resetSlideshow();
  },
};
</script>

<style lang="scss" scoped>
.slideshow {
  width: 100%;

  .imageContainer {
    max-width: 1000px;
    margin: 5px auto 5px auto;
    padding: 10px;
    border-radius: 10px;
    background-color: black;
    border: 1px solid #646154;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.slideshowController {
  text-align: center;
}

.slideshowButton {
  display: inline-block;
  cursor: pointer;
  height: 13px;
  width: 13px;
  background-color: #414141;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  transition: 0.3s ease;
}

.slideshowPrev:hover,
.slideshowNext:hover {
  background-color: #404040;
}

.slideshowActive,
.slideshowButton:hover {
  background-color: #bbbbbb;
}

.slideshowNext,
.slideshowPrev {
  font-size: 2em;
  cursor: pointer;
  transition: 0.3s ease;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
