<template>
  <section id="secHome">
    <Slideshow />
    <MenuOptionExplanations />
  </section>
</template>

<script>
import Slideshow from '../components/Slideshow/Slideshow.vue';
import MenuOptionExplanations from '../components/MenuOptionExplanations.vue';

export default {
  name: 'Home',
  components: {
    Slideshow,
    MenuOptionExplanations,
  },
};
</script>
